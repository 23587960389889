/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "bootstrap/variables";
@import "bootstrap/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
@import "bootstrap/print";
@import "bootstrap/glyphicons";

// Core CSS
@import "bootstrap/scaffolding";
@import "bootstrap/type";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";

// Components
// @import "bootstrap/component-animations";
// @import "bootstrap/dropdowns";
// @import "bootstrap/button-groups";
// @import "bootstrap/input-groups";
// @import "bootstrap/navs";
// @import "bootstrap/navbar";
// @import "bootstrap/breadcrumbs";
// @import "bootstrap/pagination";
// @import "bootstrap/pager";
// @import "bootstrap/labels";
// @import "bootstrap/badges";
// @import "bootstrap/jumbotron";
// @import "bootstrap/thumbnails";
// @import "bootstrap/alerts";
// @import "bootstrap/progress-bars";
// @import "bootstrap/media";
// @import "bootstrap/list-group";
// @import "bootstrap/panels";
// @import "bootstrap/responsive-embed";
// @import "bootstrap/wells";
// @import "bootstrap/close";

// Components w/ JavaScript
// @import "bootstrap/modals";
// @import "bootstrap/tooltip";
// @import "bootstrap/popovers";
// @import "bootstrap/carousel";

// // Utility classes
// @import "bootstrap/utilities";
// @import "bootstrap/responsive-utilities";

//slick

@import "slick/core.scss";
@import "slick/slicknav.scss";
@import "slick/theme.scss";


//fontawesome 

@import "fontawesome/fontawesome.scss";


//colours

$blue_1: #79bee8;
$blue_2: #012d6a;
$blue_3: #adced9;
$blue_4: #03678e;
$blue_5: #1a252b;
$blue_6: #141d22;
$blue_7: #008fd5;
$blue_8: #547e93;


$white: #ffffff;

$red: #ec2024;
$dark-red: #800100;
$white: #fff;

$black: #4a5359;

$grey: #ccc;
$yellow: #ffff00;



//fonts 

@font-face {
    font-family: 'VerbCondRegular';
    src: url('../fonts/VerbCondRegular.eot');
    src: url('./fonts/VerbCondRegular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/VerbCondRegular.woff2') format('woff2'),
         url('../fonts/VerbCondRegular.woff') format('woff'),
         url('../fonts/VerbCondRegular.ttf') format('truetype'),
         url('../fonts/VerbCondRegular.svg#VerbCondRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VerbCompMedium';
    src: url('../fonts/VerbCompMedium.eot');
    src: url('./fonts/VerbCompMedium.eot?#iefix') format('embedded-opentype'),
         url('../fonts/VerbCompMedium.woff2') format('woff2'),
         url('../fonts/VerbCompMedium.woff') format('woff'),
         url('../fonts/VerbCompMedium.ttf') format('truetype'),
         url('../fonts/VerbCompMedium.svg#VerbCompMedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'VerbCondBlack';
    src: url('../fonts/VerbCondBlack.eot');
    src: url('./fonts/VerbCondBlack.eot?#iefix') format('embedded-opentype'),
         url('../fonts/VerbCondBlack.woff2') format('woff2'),
         url('../fonts/VerbCondBlack.woff') format('woff'),
         url('../fonts/VerbCondBlack.ttf') format('truetype'),
         url('../fonts/VerbCondBlack.svg#VerbCondBlack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald-Stencil';
    src: url('../fonts/Oswald-Stencil.eot');
    src: url('./fonts/Oswald-Stencil.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Oswald-Stencil.woff2') format('woff2'),
         url('../fonts/Oswald-Stencil.woff') format('woff'),
         url('../fonts/Oswald-Stencil.ttf') format('truetype'),
         url('../fonts/Oswald-Stencil.svg#Oswald-Stencil') format('svg');
    font-weight: normal;
    font-style: normal;
}

body {
	opacity: 0;
	transition: opacity 1s;
	font-family: 'Open Sans', sans-serif;
	color: $black;


	@media (max-width: $screen-md-max) { 
		margin-top:70px;
	}

	@media (max-width: $screen-sm-max) { 
			margin-top:60px;
	}


	&.loaded {
		opacity: 1;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	h1, h2, h3, h4, h5 {
		font-family: 'Open Sans', sans-serif;
	}

	&.front {
		@media (max-width: $screen-xs-max) { 
			padding-top: 34px;
		}
	}

	&.admin-menu {
		 @media (max-width: $screen-xs-max) { 
			margin-top: 0!important;
		}
	}

	a, a:focus, a:hover {
		color: $blue_1;
	}

	strong {
		font-family: 'Open Sans', sans-serif;
	}

	.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
		background-color: $blue_1;
    	border-color: $blue_1;
	}

	.pagination>li>a, .pagination>li>span {
		color: $blue_1;
	}

	.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
		color: $blue_1;
	}

	

}

::selection {
	  background: transparentize($blue_1, 0.2); 
}

::-moz-selection {
  background: transparentize($blue_1, 0.2);
}

#admin-menu {
	@media (max-width: $screen-md-max) { 
		display: none;

	}
}

.slicknav_menu {

	@media (min-width: $screen-md-max) { 
		display: none;

	}

	@media (max-width: $screen-xs-max) { 
   		top: 55px;
   }

	top: 30px;

	background-color: $blue_1; 
	// background-image: url(../../resources/images/redbanner.png);

	background-position: center -300px;

   font-family: 'Open Sans', sans-serif;

    .slicknav_brand {
    	height: 30px;
    	width: 90%;
    	padding: 0;
		> a {

			display: block;
			float: left;

			img {
				width: 100px;
				margin-left: 10px;
				margin-top: 3px;
			}
		}

		.social {
			float: right;
			margin-top: 2px;

	
			@media (max-width: $screen-xs-max) { 
				margin-right: 5px;
			}

			a {
				color: $white;
				font-size: 29px;
				margin-left: 5px;

			}
		}
	}

	.slicknav_btn {
		width: 10%;
		padding: 0;
		margin: 0;
	}

	.slicknav_icon {
		float: right;
		margin: 10px;
	}


    .slicknav_nav {

    	.slicknav_icon {

    	}

    	

    	a {
			span {
    			color: $white;
    		}
    	
    		&.join-cmfeu {
    			background-color: $black;
    		}
    	}
	}	
}


@mixin buttons($color, $background-color, $size) {
	font-family: 'Open Sans', sans-serif;
	clear: both;
	background-color: $background-color;
	color: $color;
	text-transform: uppercase;
	line-height: 20px;
	-webkit-transition: all .2s;
	transition: all .2s;

	border: 1px solid $color;
	border-radius: 0;
	&:hover {
		text-decoration: none;
		color: $background-color;
		background-color: $color;
		border-color: $background-color;

	}

	// @if ($color == $blue_1) {
	// 	background-color: $blue_1;
	// 	color: $white;
	// 	border: 1px solid $white;

	// 	&:hover {
	// 		color: $blue_1;
	// 		background-color: $white;
	// 		outline: 1px solid $blue_1;
	// 	}
	// }

	@if ($size == 'small') {
		font-size: 12px;
	    padding: 7px 10px;
	 }

	 @else {
		padding: 10px 20px 10px 20px;
		font-size: 19px;
	 }



}

@mixin menu {
	li {
		list-style-type: none;
		position: relative;

		a {
			

			&#campaigns_link::before {
			    content: "\f007";
			}

			&#news_link::before {
			    content: "\f1ea";
			}
			&#rdo_link:before {
			    content: "\f073";
			}

			&#workplace_safety::before {
			    content: "\f071";
			}

			&#details_link:before {
			    content: "\f007";
			}

			&#training_link:before {
			    content: "\f15c";
			}

			&#eas:before {
			    content: "\f095";
			}

			&#education:before {
			    content: "\f5da";
			}

			&#join:before {
			    content: "\f0c0";
			}

			color: $black;
			font-size: 16px;


			&:before {
				font-family: 'Font Awesome 5 Free';
				margin-right: 20px;
				font-size: 18px;
				float: left;
				font-weight: 900;
			}
								
			@media (max-width: $screen-sm-max) { 
				font-size: 12px;
			}

			padding: 20px 10px;
			border-bottom: 1px solid $grey;
			display: block;
			transition: background-color 0.5s;


			&:hover {
				background-color: $blue_1;
				color: $white;
				text-decoration: none;
			}

		}
	}
}

html {

	.button, button, .btn {
		
		@include buttons($blue_1, $white, 'large');

		&.read-more {
			padding: 10px 15px 10px 20px;
			display: block;
		}

		&.red {
			background-color: $blue_1;
			color: $white;

			&:hover {
				color: $blue_1;
				background-color: $white;
				outline: 1px solid $blue_1;
			}
		}

		&.left {
			float: left;
		}

		&.right {
			float: right;
		}
	} 

}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.team-members {

	.view-filters {
		display: none;
	}


	.slats {
		list-style-type: none;
		padding: 0;

		li {
			border-bottom: 1px solid $grey;

			img {
				float: left;
				border: 1px solid lighten($black, 90%);
				padding: 5px;
				margin-right: 30px;
				margin-bottom: 70px;

				@media (max-width: $screen-sm-max) { 
					clear: both;
					float: none;
					display: block;
					margin: 0 auto;
				}
			}

			.position {
				display: block;

				&:first-child {
					font-weight: bold;
					margin-bottom: 10px;
				}

			}
		
			a {
				color: $black;
			}
		}
	}

	.view-id-team_members input {
	    background-color: rgb( 241, 241, 238 );
	    width: 248px;
	    height: 36px;
	    font-size: 13px;
	    font-family: "equip_slabregular",sans-serif;
	    color: rgb( 25, 26, 26 );
	    font-style: italic;
	    line-height: 1.538;
	    text-align: left;
	    padding-left: 10px;
	    border-color: rgb( 241, 241, 238 );
	}

	.views-exposed-form .views-exposed-widget {
	    float: left;
	    padding: 0;

	    input {
	    	border-radius: 0;
	    }

	    button {
	    	@include buttons($blue_1, $white, 'large');
	    }
	}

}

header#top-header{

    background-color: $blue_2;	
    overflow: hidden;
    height: 40px;


    .container {
		 @media (max-width: $screen-md-max) { 
		 	width: 100%;
		 	padding: 0;
		 }
    }

    .col-sm-12 {
    	 @media (max-width: $screen-md-max) { 
    	 	padding: 0;
    	 }
    }

    @media (max-width: $screen-md-max) { 
		position: fixed;
		z-index: 3;
		top: 0;
		width: 100%;
		height: 30px;

	}

   @media (max-width: $screen-xs-max) { 

   		 height: 55px;
   }

   .search-box {

   		@media (max-width: $screen-xs-max) { 
			height: 32px;
			padding-top: 5px;
   		}

	   .form-search  {
	   	.input-group {

	   		input {

				background-color: $blue_2;
				color: #fff;
				margin: 3px 0;
				padding: 11px;
				height: 0;
				border: 0;
				font-size: 10px;
				border: 1px solid $white;
				border-right: 0;
				border-radius: 0;

				&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
				    color: #fff;
				    opacity: 1; /* Firefox */
				    font-size: 10px;
				}

				&:-ms-input-placeholder { /* Internet Explorer 10-11 */
				    color: #fff;
				    font-size: 10px;
				}

				&::-ms-input-placeholder { /* Microsoft Edge */
				    color: #fff;
					font-size: 10px;
				}

			}
	   	}

	   	.input-group-btn {
	   		button {
	   			color: #fff;
				
				.fa {
					color: #fff;
				}	

				font-size: 12px;
				padding: 1px 10px;
				background-color: transparent;
				border: 1px solid $white;
				border-left: 0;
			
	   		}
	   	}

	   }
	 }

	ul {
		float: right;

		 @media (max-width: $screen-sm-max) { 
		 	float:none;
		 	padding: 0;
		 	text-align: center;
		 	margin-top: 3px;
		 }

		li {
			display: block;
			float: left;
			font-family: equip_slabregular,sans-serif;
			font-size: 10px;
			padding: 7px 5px 10px 7px; 
			

			 @media (max-width: $screen-sm-max) { 
			 	    padding: 2px 5px 0 1px;
			 	    float: none;
			 	    display: inline-block;
			 		font-size: 7px;
			 }

			a {
				color: $white;
				padding: 0 10px 0 0px;
				border-right: 1px solid $white;

				 @media (max-width: $screen-xs-max) { 
				 	padding: 0 5px 0 0;
				 }
			
			}

			&:last-child {
				a {
					border-right: none;
				}
			}

		}
	}
}

header#site-main-header {
	//background: url('../../resources/images/yellowbanner.png');
	background-size: cover;
	background-color: $white;
	// background-image: url(../../resources/images/redbanner.png);
    background-size: cover;
	z-index: 1;
    position: relative;

    @media (max-width: $screen-xs-max) { 
		padding: 0;
	}

	.site-header {

		.branding {
	    	
	    	@media (max-width: $screen-md-max) { 
				display: none;
			}
	 
		    .logo {
		    	a.logo {
					width: 100%;
					height: auto;
		    		img {
		    			display: block;
		    			width: 100%;
		    		}
		    	}
		    }


			.main-menu {

				@media (max-width: $screen-md-max) { 
					display: none;
				}	

				.main_navigation {

					.menu {
						float: right;
						margin: 30px 0 0 0;
						padding-left: 0;

						@media (max-width: $screen-sm-max) { 
							margin: 0;
						}

						li {
							list-style-type: none;
							display: block;
							float: left;
							font-family: 'Open Sans', sans-serif;

							a {
								color:  $blue_2;
								text-transform: uppercase;
								display: block;
								padding: 10px 7px;
								// margin: 0 1px;
								font-size: 15px;
								transition: all .2s;
								 font-size: 13px;

							@media (max-width: $screen-md-max) { 
								font-size: 15px;
							}

							@media (max-width: $screen-sm-max) { 
								font-size: 11px;
								padding: 5px 10px;
							}

								&:hover, &:active {
									text-decoration: none;
									color: $blue_1;
									background-color: $white;

								}

								&.join-cmfeu {
									@include buttons($blue_2, $white, 'small');
									 font-size: 13px;
									 margin-left: 10px;
								}
							}
							.sf-hidden {
								display: none;
							}

							ul {
								position: absolute;
								background-color: darken($blue_1, 10%);
								z-index: 2;
								padding: 0;
								li {
									ul {
										top:  0;
										background-color: darken($blue_1, 20%);
									}
								}
							}
						}
					}
				}
			}
		}
	}    
}

#block-views-homepage-slider-block {

	.block-title {
		display: none;
	}

	.view-homepage-slider .view-content {

		background-color: $white;
		@media (max-width: $screen-xs-max) {
			background-color: $blue_1;
			// background-image: url(../../resources/images/greybanner.png);
			background-position: center center;
			background-size: cover;
		}

		.slick-dots{
			bottom:20px;

			@media (max-width: $screen-xs-max) {
	    		bottom: 0px;
	    		position: relative;
	    	    padding: 5px 0 5px 0;
				background-color: $blue_1;
				// background-image: url(../../resources/images/redbanner.png);
				height: 40px;
				margin: 0;
				padding: 0;
				line-height: 0;

	    	}

			li {

				@media (max-width: $screen-xs-max) {
					width: 10px;
					height: 10px;
					margin: 0 10px;
					top: 50%;
					transform: translateY(-50%);
				}

				button {
					border-radius: 100%;
					$background-color: $blue_7;
		
					@media (max-width: $screen-xs-max) {
						height: 10px;
						width: 10px;
						background-color: $white;

					}

					&:before{
						font-size: 16px;
						border: 10px solid $black;
						border-radius: 100%;
						content: '';


						@media (max-width: $screen-xs-max) {
							border: 0;
							display: none;

						}
					}
					&:after	{ 
					
					}
				}
		
				&.slick-active {
					button {
						@media (max-width: $screen-xs-max) {
							background-color: $black;

						}
					}
				}

			}

		}

		.slick-dotted.slick-slider{
			margin-bottom: 0;
		}

		ul {
			padding: 0;
			margin: 0;

			&.slick-dots {
				@media (max-width: $screen-xs-max) { 
					// padding: 30px;
					// background-color: $blue_1;
				}
			}

			li {
				list-style-type: none;
				overflow: hidden;
					
				@media (max-width: $screen-xs-max) { 
					height: 290px;
					width: 100%;
				}

				.field-content {
					position: relative;
					width: 100%;
					img {
						width: 100%;

						@media (max-width: $screen-xs-max) { 
							margin-left: 50%;
							transform: translateX(-50%);
							height: 290px;
							width: auto;
							max-width: none;

						}

					}

				}
				
				position: relative;



				.box {
					position: absolute;
					bottom: 30px;
					left: 0;
					right: 0;

					@media (max-width: $screen-xs-max) { 
						padding-bottom: 20px;
						bottom: 0;
						position: absolute;
					}

					.button {
						@media (max-width: $screen-xs-max) { 
							border: 1px solid $blue_1;
							font-size: 16px;
							padding: 7px 11px 4px 15px;
						}
					}

					h2 {
						font-family: 'Open Sans', sans-serif;
						text-transform: uppercase;
						color: $white;
						font-size: 30px;
						display: inline;
						clear: both;
						float: right;
						background-color: $blue_4;
						padding: 10px 15px 10px 15px;
						line-height: 30px;
					
						@media (max-width: $screen-sm-max) { 
							font-size: 15px;
							padding: 0px 10px;
							margin: 10px 0px;
							// background-color: $black;
						}
					}

					a {
						@include buttons($blue_4, $white, 'large');
					}

				}

			}
		}
	}

}

// section.block-pw-nationbuilder-subscription {
// 	background-color: $blue_4;

// 	.container {
// 		width: 100%;
// 	}
	
// 	span {
// 		display: block;
// 		text-align: center;
// 		font-size: 20px;
//     	margin-bottom: 10px;
// 	}

// 	.form-group {
// 		width: 33.3333%;
// 		float: left;
// 		position: relative;

// 		@media (max-width: $screen-sm-max) { 
			
// 			width: 100%;

// 		}

// 		.fa {
// 			width: 10%;
// 			float: left;
// 			padding: 10px;

// 			@media (max-width: $screen-sm-max) { 
// 				padding: 10px 0;
// 			}
// 		}

// 		.form-item {
// 			width: 90%;
// 			float: left;
// 		}
// 	}
	
// 	.btn {
// 		width: 100%;
// 		margin-top: 10px;


// 		@media (max-width: $screen-sm-max) { 
// 			margin-top: 5px;
// 		}
// 	}

// 	color: $white;
// 	padding: 10px;
// }

.mb-2 {
	margin-bottom: 20px;
}

.mt-2 {
	margin-top: 20px;
}

.mt-3 {
	margin-top: 30px;
}

.mb-3 {
	margin-bottom: 30px;
}

.border-bottom {
	border-bottom: 1px solid $grey;
	padding-bottom: 30px;
}

.field-name-field-campaign-thumbnail-tax {
	margin: 30px 0;
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;

	iframe {
	   border: 0;
	   height: 100%;
	   left: 0;
	   position: absolute;
	   top: 0;
	   width: 100%;
	}

}
 

section.subscribe {

	background-color: $blue_4;
	// background-image: url('../../resources/images/quaternarybanner.jpg');
	// background-position: 0 -190px;
 //    background-size: cover;

	.region-featured {
		float: left;
		width: 83.33333%;

		@media (max-width: $screen-sm-max) { 
			width: 100%;
		}

		.newsletter-signup {
		    text-align: center;
		    padding: 30px 0 26px;
		    color: $white;
		    text-transform: uppercase;

		    @media (max-width: $screen-md-max) { 
				padding: 20px 0 10px
			}

			@media (max-width: $screen-sm-max) { 
				padding: 10px 0 10px;
			}



		    .container {

				text-align: center;
				width: 840px;
				margin-left: 0;
				padding-right: 0;
				margin-right: 0;


		    	@media (max-width: $screen-md-max) {
		    		width: 100%;
		    		margin-bottom: 10px;
		    	}

		    	@media (max-width: $screen-xs-max) {
		    		padding-left: 0;
		    	}



			    span {
			    	font-family: 'Open Sans', sans-serif;
					font-size: 20px;
					margin-right: 10px;
					padding-top: 4px;
					display: block;
					float: left;

			    	@media (max-width: $screen-md-max) { 
						font-size: 20px;
						margin-top: 3px;
					}

					@media (max-width: $screen-sm-max) { 
						font-size: 22px;
						margin-top: 0;
					}

					@media (max-width: $screen-xs-max) {  
						margin: 0 auto;
						display: block;
						text-align: center;
						font-size: 30px;
						float: none;

					}

			    }

			    .form-group {


			    	&:nth-child(1) {
			    		.input-icon {
						
						}
					}

					&:nth-child(2) {
						.input-icon {
							width: 200px;

							@media (max-width: $screen-md-max) {  
								width: 130px;
							}

							@media (max-width: $screen-xs-max) {  
								width: 100%;
							}

							
						}
					}

					&:nth-child(3) {
						.input-icon {
							width: 200px;

							@media (max-width: $screen-md-max) {  
								width: 130px;
							}

							@media (max-width: $screen-xs-max) {  
								width: 100%;
							}

						}
					}

					&:nth-child(4) {
						.input-icon {
							width: 110px;

							@media (max-width: $screen-md-max) {  
								width: 130px;
							}

							@media (max-width: $screen-xs-max) {  
								width: 100%;
							}
						}
					}

					&:nth-child(5) {
						.input-icon {
							width: 70px;

							@media (max-width: $screen-md-max) {  
								width: 130px;
							}

							@media (max-width: $screen-xs-max) {  
								width: 100%;
							}

						}
					}

			    	.input-icon {
						border-radius: 0px;
						margin-right: 10px;
						padding: 0px 0px;
						position: relative;

						

						[class*=fa-] {
							color: $white;
						    position: absolute;
						    top: 50%;
						    left: 15px;
						    margin-top: -8px;
						    font-size: 15px;
						}
					}

				    input {
						background-color: transparent;
						color: $white;
						padding: 10px;
						border: 1px solid #fff;
						display: block;
						// margin: 5px;
						width: 100%;
						text-indent: 30px;
						border-radius: 0;
						font-size: 10px;

						@media (max-width: $screen-md-max) {  
							text-indent: 30px;
						}

						&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
						  color: $white;
						}
						&::-moz-placeholder { /* Firefox 19+ */
						  color: $white;
						}
						&:-ms-input-placeholder { /* IE 10+ */
						  color: $white;
						}
						&:-moz-placeholder { /* Firefox 18- */
						  color: $white;
						}

						@media (max-width: $screen-xs-max) { 
							margin-bottom: 10px;
						}

					}


				}

				 button {
			    	
					@include buttons($white, $blue_4, 'large');

			    	padding: 8px 10px 7px 10px;

			    	@media (max-width: $screen-xs-max) { 
			    		width: 100%;
			    	}
			    }
		  
			}

		}
	}

	.social {

		padding: 0;

		@media (max-width: $screen-md-max) { 
    		text-align: center;
    	}

		.fab {
			@media (max-width: $screen-md-max) { 
				font-size: 33px;
			}
		}


		a {
			color: $white;
			font-size: 40px;
			margin-top: 70px;
			display: block;
			float: right;
			margin: 18px 5px 0 5px;

			@media (max-width: $screen-md-max) { 
	    		margin: 8px 5px 0 5px;
	    	}

			@media (max-width: $screen-sm-max) { 
	    		display: inline;
	    		float: none;
	    	}
		
		}
	}
}



section.main {
	padding: 70px 0;
	background-color: $white;

	@media (max-width: $screen-xs-max) { 
		padding: 0 0 20px 0;
	}

	h1 {
		margin-bottom: 20px;

		@media (max-width: $screen-xs-max) { 
			font-size: 20px;
		}
	}

	.read-more {
		margin-top: 10px;
	}

	.frame {
		// border: 7px solid $grey;
		margin: 40px 0;
		transform: rotate(6deg);
	}
}


section.messages {
	background-color: $white;
}

section.middle, section#block-system-main {
	padding: 10px 0 80px 0;
	background-color: $white;

	h2 {
		@media (max-width: $screen-sm-max) { 
			font-size: 20px;
		}
	}

	a, a:focus, a:hover {
	    color: $blue_2;
	    font-weight: bold;
	    text-decoration: underline;
	}


	img {

		
		@media (max-width: $screen-sm-max) { 
			height: auto!important;
			max-width: 100%!important;
		}

		&.left {
			float: left;
			margin-right: 10px;
			margin-bottom: 10px;

			@media (max-width: $screen-sm-max) { 
				float: none;
				margin-right: auto;
			
			}

		}

		&.right {
			float: right;
			margin-left: 10px;
			margin-bottom: 10px;

			@media (max-width: $screen-sm-max) { 
				float: none;
				margin-left: auto;
			}
		}

		@media (max-width: $screen-sm-max) { 
			margin: 0 auto;
			display: block;
			max-width: 100%;
		}
	}

	.not-front.not-logged-in & {
		@media (max-width: $screen-sm-max) { 
			margin-top: 40px;
		}
	}

	.logged-in & {
		@media (max-width: $screen-sm-max) { 
			margin-top: 40px;
		}
	}


	.fast-find {

		// background-color: blue;

		.block-title {
			&:before {
				font-family: 'font awesome 5 free';
				content: "\f35a";
				margin-right: 10px;
				font-size: 26px;
			}
		}

		.fast-find-services {
			width: 100%;
		}

		ul {
			padding: 0;
			margin: 0;
			margin-top: 10px;
		
			@include menu;
		}
	}

	// .news, .view-id-branch_new {
		.news-item-home {
			position: relative;

			.info {
			    background-color:  transparentize($blue_3, .1);
			    padding: 10px;
			    color: #fff;
			    display: inline-block;
			    position: absolute;
			    bottom: 70px;
			}

			h3 {
				a {
					padding: 10px 0;
					font-size: 17px;
					font-family: 'Open Sans', sans-serif;
					background-color: transparentize($blue_4, .2);
					color: #fff;
					padding: 10px;
					width: 100%;
					display: block;
					position: relative;
					height: 70px;
					border-bottom: 1px solid #000;
					-webkit-transition: background-color .5s;
					transition: background-color .5s;
					position: absolute;
					bottom: 0;

				}
			}
		}
	// }

	.sidebar {

		iframe, .fb-page fb_iframe_widget, .fb_iframe_widget span {
			width: 100%!important;
		}

		.fb_iframe_widget {
			display: block;
		}

		.well {
			background-color: transparent;
			border: 0;
			box-shadow: none;

			.menu-block-wrapper {

				background-color: lighten($grey, 15%);
				padding: 20px;
				    margin: 40px 0 20px;

				ul {
					@include menu;

					li {
						a {
							padding: 3px 10px;
							font-family: 'Open Sans', sans-serif;
						}
					}
				}
			}

			
		}

	}

	.social-sidebar {

		margin-top: 30px;

		ul {
			li {
				a {
					padding: 10px;
				}
			}
		}		
	}

	.featured-news-read-more {
		margin: 5px 15px;
		color: $blue_1;
		border: 1px solid $blue_1;
		padding: 10px;
		display: inline-block;
		transition: all .2s;

		&:hover {
			background-color: $blue_1;
			color: $white;
			text-decoration: none;
		}
	}

	.page-members-member-benefits & {
		.benefits {
			.benefits-list {
				@media (max-width: $screen-xs-max) {   
					width: 100%;
					clear: both;
					margin-bottom: 0px;
					overflow: hidden;
				}
			}
		}
	}

	.page-leadership-message & {

		img {
			float: left;
			margin: 0px 10px 0px 0;

			@media (max-width: $screen-xs-max) {   
					margin: 29px auto;
					display: block;
					clear: both;
					float: none;
			}
		}

	}

	.page-members-member-benefits & {
		.benefits-list {
			a.benefit-more {
				@include buttons($blue_1, $white, 'small');

				display: block;
				float: left;
				clear: none;
				margin-top: 10px;
				margin-bottom: 20px;
			}
		}
	}

	.node-type-news-centre & {

		.region-content {
			padding-top: 30px;

			.published_date {
				    font-family: 'Open Sans', sans-serif;
			}

			.field-name-field-news-thumbnail {
				float: left;
				padding-right: 20px;
				padding-bottom: 20px;
				
			}

			.published_date {
			    font-size: 12px;
			    padding: 10px 10px 0px 10px;
			    text-align: center;
			    background-color: $blue_1;
			    color: #fff;
			    display: inline-block;
			    line-height: 12px;
				padding-bottom: 6px;
				margin-bottom: 10px;
			}

		}

	}

	.page-join & {
		.container-inline-date .date-padding {
			float: none;
			padding: 0;
		}

		#edit-terms {
			margin-left: 0;
		}
	}

	.node-type-policy-research & {
		.field-name-field-news-thumbnail {
			img {
				width: 50%;
				float: left;

				@media (max-width: $screen-xs-max) {   
						margin: 30px auto;
						display: block;
						clear: both;
						width: 100%;
						float: none;
				}
			}
		}
	}

	.page-campaigns & {
		.campaign-list-item {
			display: block;
			width: 100%;
			overflow: hidden;
			padding-bottom: 30px;
			border-bottom: 1px solid $grey;
			margin-bottom: 30px;



			.campaign-donate, .campaign-read-more {
		
				@include buttons($blue_1, $white, 'small');

				display: block;
				float: left;
				clear: none;
				margin-top: 30px;
				margin-right: 5px;
			
			}

			img {
				@media (max-width: $screen-xs-max) {   
						margin: 30px auto;
						display: block;
						clear: both;
						width: 100%;
						float: none;
				}
			}
		}
	}	



	.page-news-centre &, .page-policy-research & {
		.news-item {

			&:first-child {
				margin-top: 20px;
			}

			display: block;
			width: 100%;
			overflow: hidden;
			padding-bottom: 30px;
			border-bottom: 1px solid $grey;
			margin-bottom: 30px;

			.news-media-type {
			font-family: 'Open Sans', sans-serif;
				a {
					@include buttons($blue_1, $white, 'small');
					font-family: 'Open Sans', sans-serif;
					display: inline-block;
					margin-top: 1px;
				}
			}

			h2 {
				margin-top: 10px;
			}

			.news-created {
				font-family: 'Open Sans', sans-serif;
				margin-bottom: 10px;
			}

			.news-more {
				@include buttons($blue_1, $white, 'small');
				margin-top: 10px;
				display: inline-block;
			}
		}


	}

	.page-contact & {
		label {
			font-family: 'Open Sans', sans-serif;
		}

		.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
			margin: 0;
		}

		.views-field-field-office-address {
			margin-bottom: 20px;
		}

		.google_map_field_display {
				width: 100%;
				height: 500px;
				margin: 20px 0px;

				@media (max-width: $screen-xs-max) { 
					height: 200px
				}

		}

		.view-office-location {
			margin: 20px 0;
			border-top: 1px solid $grey;
			border-bottom: 1px solid $grey;
			padding: 30px 0;
		}

		.views-exposed-widget {
			margin-bottom: 10px;
		}

		.views-label {
			font-weight: bold;
		}

		.form-checkbox {
			position: absolute;
			left: 0;
		}

	}

	.ui-accordion {

		.views-accordion-header {
			background: $blue_1;
			border: 0;
			border-radius: 0;
			color: $white;

			a {
				color: $white;
			}

			&.ui-accordion-header-active {

					background: $black;

				.ui-accordion-header-icon {
					&:after {
						content: '';
						width: 2px;
						height: 10px;
						background-color: #fff;
						position: absolute;
						left: 4px;
						display: none;
					}
				}
			}

			.ui-accordion-header-icon {
				background-image: none;
				text-indent: 0;
				display: block;
				left: 12px;
				top: 50%;
				margin-top: -5px;


				

				&:before {
					content: '';
					width: 10px;
					height: 2px;
					background-color: #fff;
					position: absolute;
					top: 4px;
				}

				&:after {
					content: '';
					width: 2px;
					height: 10px;
					background-color: #fff;
					position: absolute;
					left: 4px;
						transition: all 500ms;
				}
			}



			.ui-accordion-content {
				border-radius: 0;
				color: $white;
			}
		}
	}



}

.campaign-banner {

	.view-parallax-slider2 {

		.col-lg-4 {		

			padding: 0;

		}

	}

	.join-fight {

		background-size: cover;
		height: 500px;
		position: relative;
		background-position: center;

		@media (max-width: $screen-xs-max) { 
			height: 540px;
		}

		.text-center {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: $white;
			text-align: center;
			left: 0;
			right: 0;

			img {
				margin: 50px auto;
				width: 300px;
			}

			p {
				padding: 0 40px;
			}

			.btn {
				display: inline-block;
				margin-top: 40px;

				@media (max-width: $screen-xs-max) { 
					margin-top: 0px;
				}
			}

			h3 {
				font-size: 60px;

				@media (max-width: $screen-xs-max) { 
					font-size: 50px;
				}
			}

			
		}

	}
}

.divider-banner {
	background-color: $blue_7;
	background-size: cover;
	padding: 30px 0;
	color: $white;
	text-align: center;
    position: relative;

    
	// // .ie11 &, .ie10 &, .ie9 &  {
	// 	height: 300px;
	// // }
    
	&:before {
		content: '';
		background-image: url('../../resources/images/divider.svg');
		width: 50%;
		position: absolute;
		bottom: -99%;
		height: 100%;
		right: 0;
		z-index: 1;
		background-repeat: no-repeat;
		background-size: cover;

		 .ie11 &, .ie10 &, .ie9 &  {
			content: none;
		}
	}

	h3 {
		 // font-family: 'Oswald-Stencil';
		 color: $white;
		 // height: 150px;
		
		font-size: 77px;
		margin: 0 0 20px 0;

		@media (max-width: $screen-sm-max) { 
			font-size: 37px;
		}
	}

	p {
		//  .ie11 &, .ie10 &, .ie9 &  {
		// 	margin-top: 70px;
		// }
	}
}

.leadership {
	background: url('../../resources/images/whitebanner.jpg') no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	padding: 70px 0;

	@media (max-width: $screen-xs-max) { 
		text-align: center;
	}

	.bio {
		background-color: $white;
		border: 2px solid $black;

		img {
			display: none;
		}

		@media (max-width: $screen-xs-max) {  
			border-bottom: none;
			height: auto;
		}

		h3 {
			margin-bottom: 30px;
			font-size: 30px;

			a {
				color: $black;
			}

			@media (max-width: $screen-md-max) { 
				font-size: 14px;
				margin: 15px 0;

			}

			@media (max-width: $screen-sm-max) { 
				font-size: 14px;
			}

		}


		h4 {
			@media (max-width: $screen-sm-max) {  
				font-size: 10px;
			}
		}

		a:not(:first-child) {
			
				clear: both;
				display: inline-block;
				float: none;
				margin-bottom: 30px;

				@include buttons($blue_1, $white, 'large');


				@media (max-width: $screen-md-max) { 
					margin-bottom: 0px;
					font-size: 10px
				}

				@media (max-width: $screen-sm-max) {  
					font-size: 12px;
				}
			
		}

		
	
		.team-member-details {

			font-family: 'Open Sans', sans-serif;
			text-transform: uppercase;

			span {
					display: block;

				&.executive-name {
					font-size: 24px;

					@media (max-width: $screen-md-max) { 
						font-size: 14px;
						margin: 10px 0;
					}
				
				}

				&.executive-position {
					font-size: 15px;
					margin: 10px 0;

					@media (max-width: $screen-md-max) { 
						font-size: 10px;
					}
				}
			}

		
		}

	}

	.bio-video {

		@media (max-width: $screen-xs-max) { 
			border: 2px solid $black;
			border-top: none;
			background-color: $white;
			padding: 30px 20px;

		}

		.videoWrapper {
			border: 2px solid $black;
			background-color: $black;
			
			@media (max-width: $screen-xs-max) {   
				border-top: none;
			}

		}
	}
}

footer#site-footer {
	//background: url('../../resources/images/yellowbanner.png');
	background-size: cover;
	// background-image: url(../../resources/images/redbanner.png);
	background-size:cover;
	background-color: $blue_5;
	padding: 30px 0;
	color: $white;

	@media (max-width: $screen-xs-max) { 
		text-align: center;
	}

	.footer-col {
		@media (max-width: $screen-xs-max) { 
			border-bottom: 1px solid transparentize($white, 0.75);
			padding: 0px 0 20px 0;
		}
	}

	h2 {
		font-size: 20px;
		margin-bottom: 20px;

		@media (max-width: $screen-sm-max) { 
			font-size: 18px;
		}

	}

	.logo {
		img {
			margin-bottom: 30px;
		}

		.button {
			margin-top: 30px;
		}
	}

	a {
		color: $white;
	}

	li, p {
		@media (max-width: $screen-xs-max) { 
			font-size: 12px;
		}
	}

	.footer-contact, .footer-address {
		display: block;
		clear: both;
		width: 100%;
		margin-bottom: 10px;

		.fab {
			font-size: 15px;

			
		}
	}

	.quick-links {

		.fab {
			font-size: 15px;
			margin-right:10px;


		}
		
	}

	.social-icons {
		.fab {
			margin-right: 10px;

			@media (max-width: $screen-sm-max) { 
				font-size: 32px;
			}
		}
	}

	a.footer-link {
		@include buttons($white, $blue_5, 'small');
		margin: 10px 0;
    	display: inline-block;
	}


	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	> div {
		> div {
			margin-bottom: 30px;
		}
	}	

	.cfmeu-vic-callout {
		margin: 20px 0;
	}

	.text-left, .text-right {
		

		@media (max-width: $screen-xs-max) {   
			float: none!important;
			text-align: center;
			text-align: center;
			width: 100%;
			display: inline-block;
			margin: 5px 0;
		}
	}
}

.end {
	// background-image: url('../../resources/images/secondarybanner.jpg');
    // background-size: cover;
    background-color: $blue_6;
	color: $white;
	text-align: center;
	padding: 20px;
	img {
		width: 150px;
		margin-bottom: 20px;
	}
}

.branch-navigator {
	width: 340px;
	overflow: hidden;
	float: right;
	position: fixed;
	bottom: 0;
	right: 10px;
	background-color: $blue_4;
	font-size: 20px;
	padding: 10px;
	z-index: 5;
	padding-top:3px;


	@media (max-width: $screen-xs-max) { 
		width: 100%;
		right: auto;
		left: auto;

	}


	a {
		color: #fff!important;
		font-size: 15px;
		margin: 0;
		text-decoration: none!important;

		&:hover {
			color: #fff;
			text-decoration: none;
		}

		&.expanded {
			.fas {
				transform: rotate(180deg);
			}
		}

	}

	transition: max-height 250ms;

	// &.expanded {
	// 	 max-height: 1000px;
	// }

	h2 {
		color: #fff;
		font-size: 16px;
		font-weight: normal;
		font-family: 'Open Sans', sans-serif;
		margin: 10px;
	}

	h4 {
		color: #fff;
		font-size: 16px;
		margin: 0;
		margin-left: 30px;
		font-family: 'Open Sans', sans-serif;
		margin-bottom: 5px;
	}

	.menu-item {
		ul {
			display:none;
			transition: max-height 1s;
			overflow:hidden;
			margin: 0px 0 10px 0;
			list-style-type: none;

			&.expanded {
				display: block;
			}

			li {
				line-height: 1;
				a {
					font-size: 14px;
				}
			}
		}
	}

	.branch-locator-content {
		display: none;

		&.expanded{
			display: block;
		}
	}
}

.view-home-page-content {
	.view-content {
		.views-row {
			.col-sm-7 {
				overflow: hidden;
			}
		}
	}
}


.form-signup.panel-group {
	margin: 20px 0;

		@media (max-width: $screen-sm-max) { 
			margin: 10px 0;

		}

	.panel-default > .panel-heading {
		background-color: #03678e;
		color: white;

		.panel-title {
			
			font-size: 26px;

			> a {
				&:hover, &:focus, &:active {
					color: white;
				}
			}
		}
	}
}


#node-3924 {

	figure {
		float: right;
		margin-left: 20px;
		margin-bottom: 0;
		width: 50%;

		img {
			width: 100%;
		}

		@media (max-width: $screen-sm-max) {  

			width: 100%;
			margin: 0;
		}
	}

	figcaption {
		text-align: center;
		background: #dddddd;
		padding: 5px;
		font-weight: bold;
	}
}

.page-node-3924 {
	section#block-system-main {
		padding-bottom: 0;
	}
}


.corona-resource {
	
	@media (max-width: $screen-sm-max) { 
	    padding-bottom: 40px;
	    padding-top: 30px;
    	border-bottom: 1px solid #e2e2e2;
	}

	min-height: 300px;
	margin-bottom: 30px;

	.views-field-title {
		border-top: 1px solid #eee;
		padding-top: 10px;
		background-color: #eee;
		padding: 10px;
		border-top: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
		height: 90px;

		h4 {
			font-size: 11px;

			  @media (max-width: $screen-sm-max) { 
				font-size: 14px;
			  }
		}

	    @media (max-width: $screen-sm-max) { 

			height: auto;
	    }
	}

	.views-field-field-news-thumbnail {
		border-left: 1px solid #cccccc;
	    border-right: 1px solid #ccc;

	    .img-responsive {
	    	width: 100%;
	    	height: auto;
	    }

	}

	.views-field-views-conditional {
		border-top: 1px solid #eee;
		padding-top: 10px;
		background-color: #eee;
		padding: 10px;
		border-bottom: 1px solid #ccc;
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;

		a {
			color: #4a5359;
   			font-size: 11px;
    		font-weight: bold;
		}
	}
}

.corona-form {
	form {
		background-image: url(https://www.mua.org.au/sites/mua.org.au/files/form-blue.jpg);
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    padding: 20px;
	    color: white;
	    margin-bottom: 30px;

	    .form-control {
	    	background-color: rgba(255, 255, 255, 0.2);
	    	color: white;
    		border: 1px solid rgba(255, 255, 255, 0.2);
	    }

	    .btn {
			width: 100%;
			background-color: #131d22;
			border: 1px solid white;
			color: white;
	    }
	}
	
	.block-title {
		font-size: 19px;
		text-align: center;
		background: #1a252a;
		margin: 0;
		padding: 22px;
		color: white;
		font-weight: bold;
	}

}

.corona-form .content .form-group .form-control, .corona-form .content .form-group select {
     background-color: rgba(255, 255, 255, 0.07)!important;
    color: white;
    border: 1px solid white;
    border-radius: 0; 
}

 .corona-form .content .form-group select {

    background: url(https://me.cfmeu.org.au/sites/me.cfmeu.org.au/files/select-bg-mining.jpg) 100% 15% no-repeat rgba(255, 255, 255, 0.07) !important;
  
}

 .corona-form .content .form-group select option {
 	color: black; 
}


